import { UAParser } from 'ua-parser-js';
import { isSSR } from 'utility/isSSR';

const Android = 'Android';
const Firefox = 'Firefox';
const Safari = 'Safari';
const SafariMobile = 'Mobile Safari';
const iOS = 'iOS';
const Edge = 'Edge';
const tablet = 'tablet';
const mobile = 'mobile';

const clientParser = new UAParser();

type BrowserFlags = {
    isMobileOrTablet: boolean;
    isIOS: boolean;
    isAndroid: boolean;
    isEdge: boolean;
    isFF: boolean;
    isSafari: boolean;
    isSafariMobile: boolean;
    isIpad: boolean;
    isIpadPro: boolean;
    isIphone: boolean;
    isTablet: boolean;
    isMobile: boolean;
    isSurface: boolean;
    browserName: string;
    osName: string;
};

const getBrowserFlags = (uaParser: typeof clientParser): BrowserFlags => ({
    isMobileOrTablet:
        uaParser.getDevice().type === mobile ||
        uaParser.getDevice().type === tablet ||
        uaParser.getOS().name === Android,
    isAndroid: uaParser.getOS().name === Android,
    isIOS: uaParser.getOS().name === iOS,
    isEdge: uaParser.getBrowser().name === Edge,
    isFF: uaParser.getBrowser().name === Firefox,
    isSafari: uaParser.getBrowser().name === Safari,
    isSafariMobile: uaParser.getBrowser().name === SafariMobile,
    isIpad: uaParser.getDevice().type === tablet && uaParser.getOS.name === iOS,
    // Exception for iPad Pro - https://stackoverflow.com/questions/57776001/how-to-detect-ipad-pro-as-ipad-using-javascript
    isIpadPro: uaParser.getBrowser().name === Safari && navigator.maxTouchPoints > 2,
    isIphone: uaParser.getDevice().type === mobile && uaParser.getOS.name === iOS,
    isTablet: uaParser.getDevice().type === tablet,
    isMobile: uaParser.getDevice().type === mobile,
    isSurface: !isSSR && !!((window.navigator as any).pointerEnabled && navigator.maxTouchPoints > 1),
    browserName: uaParser.getBrowser().name,
    osName: uaParser.getOS().name,
});

export const isProgressiveWebApp = (): boolean =>
    !isSSR &&
    ((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) ||
        // eslint-disable-next-line dot-notation
        window.navigator['standalone'] === true);

export default getBrowserFlags(clientParser);
