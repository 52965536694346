import { getUserId, setUserId } from 'storage/localStorage/userStorage';
import { setSessionItem } from 'storage/sessionStorage/utility/sessionStorage';
import { isSSR } from 'utility/isSSR';
import merge from 'utility/merge';
import { getQueryParams } from 'utility/url';
import { ExtendedConfig, getExtendedConfig } from './extendedConfig';

export function isDev() {
    return process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local';
}

if (typeof window !== 'undefined') {
    window.name = 'ZinioWeb';
    window.ZinioWebVersion = process.env.VERSION;
    window.Promise.prototype.cancel = function () {
        this._isCancelled = true;
    };
    window.Promise.prototype.isCancelled = function () {
        return this._isCancelled as boolean;
    };
}

let initialConfig: Partial<State.ConfigType> = {
    CDN_PATH: 'https://cdn2.audiencemedia.com/',
    WEB_ROOT: '',
    ASSETS_PATH: '/',
    HISTORY_TYPE: 'HASH',
    version: process.env.VERSION,
    isDEV: isDev(),
    DESKTOP: {
        externalLinks: {},
    },
};

export function getConfig() {
    if (isSSR) {
        throw new Error('Cannot get Client Config');
    }
    return initialConfig as Partial<ExtendedConfig>;
}

export function updateConfig(config: Partial<State.ConfigType>) {
    initialConfig = getExtendedConfig(merge(initialConfig, config));
    Object.freeze(initialConfig);
    return initialConfig;
}

export function initConfig(config: Partial<State.ConfigType>) {
    updateConfig(config);

    if (config.USER_ID) {
        console.warn('Deprecated USER_ID inside initConfig');
        setUserId(config.USER_ID);
    }

    if (!config.USER_ID && getUserId()) {
        config.USER_ID = getUserId();
    }

    updateConfig(config);

    const queryParams = getQueryParams();
    const nwcountryQueryParam = queryParams.get('nwcountry');
    const removeRestrictionQueryParam = queryParams.get('removeRestriction');

    if (nwcountryQueryParam) {
        setSessionItem('nwcountry', nwcountryQueryParam);
    }
    if (removeRestrictionQueryParam) {
        setSessionItem('removeRestriction', removeRestrictionQueryParam);
    }

    return initialConfig;
}

export const FEATURE_TOGGLER = {
    SECURE_PAYMENTS_3D: true,
    GOOGLE_OPTIMIZE_EXPERIMENT: false,
    USE_ZINIO_ZINIOAPPS_STG: false,
    IMAGE_RESIZE_LAMBDA_TESTING: process.env.NODE_ENV !== 'production',
    SHOW_VOUCHER_PROMO: process.env.NODE_ENV !== 'production',
    REPORT_CSPRO: process.env.NODE_ENV !== 'production', // Enable the report of CSPRO errors in NR
    USE_CSPRO: process.env.NODE_ENV !== 'local', // Enable the CSPRO Middleware
    USE_KOUNT: true,
};
