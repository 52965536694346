import { getConfig } from 'app/config';
import { getSessionItem, setSessionItem } from '../sessionStorage/utility/sessionStorage';
import { getStorageItem, setStorageItem } from './utility/localStorage';

const USER_ID_KEY = 'id';
const USER_EXTERNAL_ID_KEY = 'external_id';
const USER_LOGIN_TYPE = 'login_type';
const EMAIL_KEY = `email`;
const PAYMENT_FULLNAME_KEY = `payment_fullname`;
const HAS_ACCOUNT = 'has_account';
const HAS_LOGOUT = 'has_logout';
const GIGYA_USER_EXTERNAL_UID_KEY = 'guid';

export function getUserId(): string {
    const { APP_TYPE, isWBA } = getConfig();
    let userId: string;
    if (APP_TYPE === 'READER') {
        userId = getSessionItem<string>(USER_ID_KEY) || getStorageItem<string>(USER_ID_KEY);
    } else if (APP_TYPE === 'NEWSSTAND' || isWBA || APP_TYPE === 'LIBRARY') {
        userId = getStorageItem<string>(USER_ID_KEY);
    }
    return /\d+/.test(userId) || getConfig().isCMS ? userId : null;
}

export const getExternalUserId = () => getStorageItem<string>(USER_EXTERNAL_ID_KEY);

export const setExternalUserId = (externalId) => setStorageItem(USER_EXTERNAL_ID_KEY, externalId);

export function getUserEmail(): string {
    return getStorageItem<string>(EMAIL_KEY);
}

export function getPaymentFullName(): string {
    return getStorageItem<string>(PAYMENT_FULLNAME_KEY);
}

export function setUserEmail(email: string) {
    return setStorageItem(EMAIL_KEY, email);
}

export function setPaymentFullName(name: string) {
    return setStorageItem(PAYMENT_FULLNAME_KEY, name);
}

export function setUserId(userId: string) {
    switch (getConfig().APP_TYPE) {
        case 'READER':
            return setSessionItem(USER_ID_KEY, userId);
        case 'LIBRARY':
        case 'NEWSSTAND':
        case 'WBA_HOME':
        case 'WBA_PRODUCT':
            return setStorageItem(USER_ID_KEY, userId);
        default:
            return null;
    }
}

export const setHasAccount = () => setStorageItem(HAS_ACCOUNT, true);

export const getHasAccount = () => getStorageItem<boolean>(HAS_ACCOUNT);

export const setUserHasLogout = (val: boolean) => setStorageItem(HAS_LOGOUT, val);

export const getUserHasLogout = () => getStorageItem<boolean>(HAS_LOGOUT);

export const getGigyaUid = (): string => getStorageItem<string>(GIGYA_USER_EXTERNAL_UID_KEY);

export const setGigyaUid = (val: string): void => setStorageItem(GIGYA_USER_EXTERNAL_UID_KEY, val);

export const setUserLoginType = (userType: number) => setStorageItem(USER_LOGIN_TYPE, userType);
const getUserLoginType = () => getStorageItem<number>(USER_LOGIN_TYPE);

enum LOGIN_TYPE {
    FACEBOOK = 2,
    GOOGLE = 3,
}

export const isSocialLoginFB = () => getUserLoginType() === LOGIN_TYPE.FACEBOOK;
export const isSocialLoginType = () => [LOGIN_TYPE.FACEBOOK, LOGIN_TYPE.GOOGLE].includes(getUserLoginType());
